@forward "reset"

input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill
  -webkit-box-shadow: 0 0 0 1000px #5c6091 inset !important
  -webkit-text-fill-color: #fff !important
  font-size: inherit
  font-style: italic
